import React, { useEffect } from 'react'
import 'twin.macro'

import tw from 'twin.macro'

import SEO from 'components/shared/seo'
import useDesignExpertise from 'content-queries/mx/new/design-expertise'
import fireEvent from 'context/tracking/events'
import { AutomaticPopup } from 'mx/automatic-popup'
import { MainContent } from 'mx/components/new'
import { PackageCompare } from 'mx/components/new/package-compare'
import Layout from 'mx/layout'

const DesignExpertisePage = ({ path }: { path: string }) => {
  const { data } = useDesignExpertise()
  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'Design Expertise',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [hero, designers, compare, banner],
      },
    },
  ] = data

  return (
    <Layout path={path}>
      <AutomaticPopup />
      <MainContent header data={hero} />
      <MainContent
        data={designers}
        inline
        dark
        classname={tw`mt-20 lg:mt-32 !bg-gray-800`}
      />
      <PackageCompare data={compare} classname={tw`mt-20 lg:mt-32`} />
      <MainContent
        data={banner}
        centerCTA
        inline
        classname={tw`my-20 lg:my-32`}
      />
    </Layout>
  )
}

export default DesignExpertisePage

export const Head = () => {
  const { data } = useDesignExpertise()
  const [
    {
      node: {
        image,
        meta: { title, description },
      },
    },
  ] = data
  return (
    <SEO
      description={description}
      path="/design-expertise"
      title={title}
      image={
        image.localFile?.childImageSharp?.gatsbyImageData?.images?.fallback?.src
      }
    />
  )
}
